$po-wrapper-width: 240px;
$po-wrapper-margin: 1rem 0;

$po-wrapper-btn-color: var(--msv-accent-brand-color);

$po-number-main-control-max-width: 500px;
$po-number-main-control-margin-bottom: 2rem;

.eve-po-number {
  display: flex;
  flex-direction: column;
  &__input {
    @include font-content-l();
    width: $po-wrapper-width;
    margin: $po-wrapper-margin;
  }
  &__button {
    @include primary-button($po-wrapper-btn-color);
  }
}

.ms-checkout__main-control {
  .eve-po-number {
    max-width: $po-number-main-control-max-width;
    margin-bottom: $po-number-main-control-margin-bottom;
  }
}
.ms-checkout-po-number__error {
  @include alert();
  display: flex;
  align-items: center;
  &-message {
    @include error-message();
  }
}