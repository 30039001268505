$gar-image-wrapper-height: 800px;

.ari-model-viewer {
    &__list {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__header {
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        background: var(--msv-header-bg);
        margin-bottom: 20px;

        &--title {
            border-bottom: 1px solid var(--ms-search-autosuggest-font-color);
            padding: 20px;
            width: 100%;
            text-align: center;
            color: var(--msv-header-font-color);
        }

        &--mobile {
            .ari-model-viewer__header--details {
                flex-direction: column;

                .ari-model-viewer__dropdown {
                    width: fit-content;
                }
            }
        }

        &--details {
            display: flex;
            padding: 20px 60px;
            align-items: center;
            justify-content: center;

            .ari-model-viewer__tools {
                display: flex;
                justify-content: flex-end;
                column-gap: 1rem;
                row-gap: 1rem;
                padding-left: 30px;
                flex-wrap: wrap;
                max-width: 450px;

                &-button {
                    @include primary-button-light;
                    min-width: 190px;
                    padding: 10px 16px;
                }

                &--mobile {
                    justify-content: center;
                    padding-left: 0;

                    .ari-model-viewer__tool-button {
                        padding: 10px 12px;
                        font-size: $msv-font-size-xs;
                    }
                }
            }
        }

        &--mobile {
            flex-direction: column;

            select {
                max-width: none;
                margin-bottom: 20px;
            }

            h3 {
                font-size: $msv-font-size-m;
            }
        }
    }
    &__dropdown {
        display: flex;
        align-items: center;
        width: max-content;

        &-select {
            @include form-dropdown-decoration;
            padding: 10px;
            max-width: 600px;
        }
    }

    &__hotspot {
        border: 1px solid $msv-gray-40;
        cursor: pointer;
        position: absolute;
        border-radius: 50%;
        &:hover {
            border: 1px solid var(--msv-primary-btn-light-bg-color);
        }
    }
    &__image-wrapper {
        height: $gar-image-wrapper-height;
        overflow: hidden;
        width: 100%;

        &--mobile {
            height: 800px;
            margin-bottom: 20px;
        }

        .ari-model-viewer__transform-wrapper {
            max-width: 100%;
            max-height: 68%;

            &--mobile {
                max-height: 62%;
                .ari-model-viewer__help-text-wrapper {
                    span {
                        left: 85vw;
                        top: 85vw;
                    }
                }
            }
        }
    }
    &__image-placeholder {
        display: flex;
        height: 50%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    &__zoom-wrapper {
        position: relative;

        img {
            max-width: none;
        }
    }

    &__ref-btn {
        @include primary-button-light;
    }
    &__transform-wrapper {
        margin: auto !important;
        height: 100% !important;
    }
    &__part-flash {
        animation-name: flash-part;
        animation-duration: 2s;
    }
    &-title-wrapper {
        display: flex;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        background: var(--msv-header-bg);
        padding: 20px 30px;
        justify-content: space-between;
        margin: 1rem 0;
        line-height: 2rem;
        width: 100%;

        &__title {
            line-height: 2rem;
            color: var(--msv-header-font-color);
        }
    }

    &__help-text {
        text-transform: uppercase;
        font-weight: 700;
        color: white;
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        @include add-icon($drag-move-icon);
        position: absolute;
        left: 25%;
        top: 40vh;
        &::before {
            font-size: 1.5rem;
            margin-right: 0.5rem;
        }
        &-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 200;
        }
    }

    &__parts-row {
        text-align: center;
        p.hide-status {
            /// not working ?
            font-size: 0.8em;
            width: 165px;
            margin: auto;
            // padding: 1em 0 0 0;
            text-align: left;
        }
    }

    &__parts-list-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: $msv-font-size-ml;

        &--part-number {
            text-align: right;
        }
    }

    &__part-selection-popover {
        position: fixed;
        z-index: 200;
        bottom: -165px;
        left: 0;
        background-color: transparent;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-table-wrapper {
            display: flex;
            width: 87% !important;
            margin-bottom: 0 !important;
            margin-left: 50px;
            bottom: 0;
        }

        &-table {
            margin-bottom: 0 !important;
            bottom: 0;
            padding-top: 10px;
            padding-bottom: 40px;
            height: fit-content;
            border-spacing: 0;
            background-color: $gardner-gray-8;
            border-radius: 5px 5px 0 0;
            border: 1px solid $msv-primary;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.6);

            td,
            th {
                vertical-align: middle !important;
                color: var(--msv-font-primary-color);
                font-family: $msv-primary-font-family;
                font-size: $msv-font-size-l;
                font-weight: 500;
                border-top: 1px solid $msv-secondary !important;
            }

            th {
                border-top: none !important;
                border-bottom: 2px solid $msv-secondary !important;
            }

            button {
                background-color: transparent;
                border: none;
                color: var(--msv-font-primary-color);
                font-family: $msv-primary-font-family;
                font-size: $msv-font-size-l;
                font-weight: 500;
                pointer-events: none;
                padding: 0;
                min-width: fit-content;
                border-radius: 0;
                letter-spacing: 0;

                &:hover {
                    box-shadow: none;
                    background-color: transparent;
                }
            }
        }

        &--opened {
            transform: translateY(-165px);
            transition: transform 1s;
        }

        &--closed {
            transform: translateY(165px);
            transition: transform 1s;
        }

        &-close-drawer {
            @include primary-button-light;
            padding: 10px;
            min-width: 0;
            width: 40px;
            font-size: $msv-font-size-m;
            letter-spacing: 0;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.6);
            margin-top: 8px;
            margin-left: 10px;
        }
        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }
}

.quantity-input {
    padding: 5px 0 5px 5px;
    border-radius: 2px;
    border: 1px solid var(--msv-quantity-border-color);
    text-align: center;
    color: #495057;
    height: 36px;
    line-height: 36px;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 0;

    &-item {
        border: 1px solid lightgrey;
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 1rem 2rem;
        background-color: $msv-white;

        .ari-model-viewer__parts-list-part-desc {
            letter-spacing: 0.05em;
            font-size: $msv-font-size-m;
            font-weight: 400;
            display: block;
            margin-bottom: 5px;
        }

        .ari-model-viewer__parts-list-item-details {
            display: flex;
            justify-content: space-between;
            letter-spacing: 0.05em;
            font-size: $msv-font-size-m;
            font-weight: 500;
        }
    }
}

.list-group-wrapper {
    max-width: 400px;
}

@media (max-width: $msv-breakpoint-l) {
    .ari-model-viewer__header--details {
        padding: 20px;

        .ari-model-viewer__tools {
            max-width: max-content;
            &-button {
                font-size: 0.8rem;
                padding: 10px 10px;
                min-width: 160px;
            }
        }
    }
}

@media (max-width: $msv-breakpoint-m) {
    .quantity-btns {
        & > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-end;
            p {
                text-align: right;
                margin: 1em 0 0.5em;
                font-size: 0.8em;
                width: 100%;
            }
        }
        display: flex;
        justify-content: flex-end;
        margin-top: 2em;
    }
    .quantity-input {
        margin-right: 2em;
    }

    .ari-model-viewer__header--details {
        padding: 15px;

        .ari-model-viewer__tools-button {
            min-width: 140px;
        }
    }
}

@keyframes flash-part {
    0% {
        background-color: $gardner-blue-1;
    }
    25% {
        background-color: transparent;
    }
    50% {
        background-color: $gardner-blue-1;
    }
    100% {
        background-color: transparent;
    }
}

.ari-model-viewer__part-selection-popover-table .add-to-cart-btn {
    pointer-events: auto;
    @include primary-button-light;
    padding: 12px;
}
