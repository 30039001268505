$order-schedule-margin-bottom: 20px;
$order-schedule-input-margin: 1rem 0;

$order-schedule-button-color: var(--msv-accent-brand-color);
.eve-order-schedule {
  margin-bottom: $order-schedule-margin-bottom;
  &__input {
    @include font-content-l();
    margin: $order-schedule-input-margin;
  }

  &__button {
    @include primary-button($order-schedule-button-color);
  }
}