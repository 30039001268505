$image-list-title-font-weight: 700;
$image-list-title-link-margin: 3px;
$image-list-title-text-margin: 3px;
$image-width-medium-layout: 65px;
$image-height-medium-layout: 65px;
$image-width-large-layout: 305px;
$image-height-large-layout: 172px;
$icon-image-width-large-layout: 70px;
$icon-image-height-large-layout: 70px;

//Style settings
:root {
    --imageBackgroundColor: #{imageBackgroundColor};
}

.ms-image-list {
    &__title {
        @include font-heading-h2-l();
        padding-bottom: 15px;
    }

    &__link {
        margin: $image-list-title-link-margin;
        background-color: var( --imageBackgroundColor);
    }

    &__text {
        @include font-body-regular-s();
        margin: $image-list-title-text-margin;
        * {
            background-color: transparent !important;
        }
    }

    &__list {
        @include font-body-regular-m();
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &.displaystyle {
        &__vertical {
            .ms-image-list__list {
                display: grid;
                justify-content: flex-start;
            }
        }

        &__horizontal {
            .ms-image-list__list {
                flex-direction: row;
            }
        }
    }

    &.imageplacement {
        &__top {
            .ms-image-list__list-item {
                flex-direction: column;
            }
        }

        &__bottom {
            .ms-image-list__list-item {
                flex-direction: column-reverse;
            }
        }

        &__left {
            .ms-image-list__list-item {
                flex-direction: row;
            }
        }

        &__right {
            .ms-image-list__list-item {
                flex-direction: row-reverse;
                text-align: right;
            }
        }
    }

    &.contentalignment {
        &__left {
            text-align: left;
            flex-direction: row;
            justify-content: flex-start;
            float: left;
        }

        &__center {
            text-align: center;

            .ms-image-list__list {
                justify-content: center;
            }
        }

        &__right {
            text-align: right;
            flex-direction: row;
            justify-content: flex-end;
            float: right;

            @media (max-width: $msv-breakpoint-m) {
                .ms-image-list__list {
                    display: inline-block;
                    justify-content: flex-end;
                }
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                .ms-image-list__list {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }

    &[data-m-layout="mediumImageSettings"] {
        .msc-image-container {
            @include image($image-width-medium-layout, $image-height-medium-layout);
        }
    }

    &[data-m-layout="largeImageSettings"] {
        .msc-image-container {
            @include image($image-width-large-layout, $image-height-large-layout);
        }
    }

    &[data-m-layout="iconList"] {
        .msc-image-container {  
            @include image($icon-image-width-large-layout, $icon-image-height-large-layout);
        }

        &__list {
            justify-content: space-between;
            max-width: 1047px;
            margin: 0 auto;
            &-item {
                min-width: 200px;
                width: 33%;
                flex-flow: column;
                margin: 0px auto;
                .ms-image-list__link {
                    background-color: var( --imageBackgroundColor);
                    border-radius: 50%;
                    width: $icon-image-width-large-layout;
                    height: $icon-image-height-large-layout;
                    margin: 0 auto;
                    padding: 1em;
                }
            }
        }
    }
}

// Image List B2C CSS

.b2c-main-image-list {
    max-width: 100%;

    .ms-image-list__list {
        .ms-image-list__link {
            margin: 10px 20px;
            border: 1px solid $msv-font-primary-color;

            img {
                height: 250px;
            }

            @media (max-width: $msv-breakpoint-lg) {
                img {
                    height: 185px;
                }
            }
        }
    }
}