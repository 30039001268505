//spacing
$header-container-max-width: 1440px;
$header-container-spacing-to-bottom-tablet: 0px;
$header-container-height-tablet: 107px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 0;
$heading-padding-bottom: 28px;
$header-height-l: 106px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 40px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 167px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 8px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-logo-margin-right-mobile: 0;
$header-logo-padding-mobile: 7px 0;
$header-logo-min-width: 116px;
$header-logo-min-height: 48ypx;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-border-radius: 100px;
$header-cart-count-padding: 2px 6px 0 6px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 10px;
$header-container-topbar-account-info-margin-left: 0;
$header-container-topbar-account-info-signin-button-padding: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-popover-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-popover-box-shadow-color: rgba(0, 0, 0, 0.13);
$msv-primary: $gardner-blue-1;

$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-accent-brand-color);
    --msv-header-font-color: var(--msv-font-secondary-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
    //Phone number style settings
    --msv-divider-color: $msv-divider-color;
    --msv-phone-number-font-color: var(--msv-header-desktop-nav-icon-background-color);
    

    //Popover
    --msv-header-account-info-popover-font-color: #{$gardner-blue-1};
    --msv-header-account-info-popover-background-color: #{$msv-white};
    --msv-header-account-info-popover-background-color-primary-darker: hsl(from var(--msv-header-account-info-popover-background-color) h s calc(l - 5%)); 

    //Nav Menu
    --msv-header-desktop-nav-icon-background-color: #{$gardner-red-1};
    --msv-header-desktop-nav-icon-background-hover-color: #{$gardner-red-1};

    //Mini-cart icon
    --msv-mini-cart-count-background-color: #{$msv-blue};
}
.desktop-navicon-container {
    order: 1;
    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        order: 0;
    }
}
.ms-header .msc-btn {
    background-color: transparent;
}

.msc-modal .ms-header__mobile-hamburger .msc-btn {
    display: block;
    background-color: var(--msv-header-bg);
}

.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;

    .ms-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
    }
}


.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    margin-left: auto;
    margin-right: auto;

    &__desktop-nav-items {
        display: flex;
        width: 100%;

        @media screen and (max-width: $msv-breakpoint-m) {
            justify-content: space-around;
            align-items: baseline;
        }
    }
    &__container {
        background-color: var(--msv-bg-color);
        margin: 0 auto;

        .ms-header__preferred-store-btn,
        .ms-search__icon,
        .ms-header__signin-button,
        .msc-wishlist-icon,
        .msc-cart-icon,
        .ms-header__profile-button {
            @include font-body-regular-m();

            color: var(--msv-header-font-color);
            border: 1px solid transparent;

            @include vfi();

            &:hover, &*:hover {
                color: var(--msv-accent-hover-focus-color);
            }

            @media (max-width: $msv-breakpoint-l) {
                height: 48px;
                width: 44px;
            }
        }

        .ms-header__quick-order {
            order: 4;
            padding-top: 7px;
            @include font-body-regular-m();
            justify-content: flex-end;
            height: $header-height;
            margin-right: 26px;
            display: flex;

            .ms-header__quick-order-button {
                @include font-body-regular-m();

                background-color: transparent;
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;
                &:hover {
                    text-decoration: none;
                    color: var(--msv-accent-hover-focus-color);
                }
                &::before {
                    @include msv-icon();
                    content: $gar-bolt-icon;
                    font-size: var(--msv-header-icon-font-size);
                    width: auto;
                    vertical-align: text-bottom;
                    text-align: center;
                    font-weight: normal;
                    color:  inherit;
                }
            }
            &-button-text {
                @media (max-width: $msv-breakpoint-l) {
                    display: none;
                }
            }
        }

        .ms-header__account-info {
            order: 3;
            padding-top: 7px;
            @include font-body-regular-m();
            justify-content: flex-end;
            height: $header-height;
            margin-right: $header-acc-info-desktop-margin-right;
            display: flex;

            .ms-header__signin-button, .ms-header__profile-button {
                @include font-body-regular-m();

                background-color: transparent;
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;

                &:hover {
                    text-decoration: none;
                    color: var(--msv-accent-hover-focus-color);
                }

                &::before {
                    @include msv-icon();
                    content: $msv-signin;
                    font-size: var(--msv-header-icon-font-size);
                    width: auto;
                    vertical-align: text-bottom;
                    text-align: center;
                    font-weight: normal;
                    color: inherit;
                }
            }

            &__account-link-container {
                height: $account-link-height;
            }

            a {
                @include font-body-regular-m();
                align-items: center;
                display: flex;
                height: $header-height;
                line-height: $msv-line-height-m;
                background-color: transparent;
                color: var(--msv-header-account-info-popover-font-color);
                white-space: nowrap;
                padding-left: 20px;
                padding-right: 20px;
            }

            .msc-popover {
                background-clip: padding-box;
                background-color: var(--msv-header-account-info-popover-background-color);
                display: block;
                left: 0;
                max-width: $account-links-max-width;
                position: absolute;
                word-wrap: break-word;
                z-index: 1060;
                box-shadow: 0 0.6px 1.8px $msv-popover-box-shadow-color, 0 3.2px 7.2px $msv-account-popover-box-shadow-color;

                .msc-popover-inner {
                    .ms-signin-info__account-link-button, .ms-header__signout-button {
                        &:hover {
                            background-color: var(--msv-header-account-info-popover-background-color-primary-darker);
                        }

                    }
                }

                .msc-btn {
                    background-color: $msv-white;
                    color: var(--msv-header-account-info-popover-font-color);
                    font-weight: normal;
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: none;
                }
            }

            .ms-profile-button-text, .ms-header__signin-button-text {
                margin-left: $msv-profile-button-text-margin-left;
                vertical-align: top;
            }
        }

        .ms-header__topbar {
            margin: 0 auto;
            max-width: $header-container-max-width;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: flex-start;
            height: $header-mobile-height;

            padding-left: $msv-layout-container-padding-m;
            padding-right: $msv-layout-container-padding-m;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding-left: $msv-layout-container-padding-s;
                padding-right: $msv-layout-container-padding-s;

                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                padding-left: $msv-layout-container-padding-l;
                padding-right: $msv-layout-container-padding-l;
            }

            .ms-header__nav-icon {
                @include vfi();
                background: var(--msv-header-bg);
                display: flex;
                align-items: center;
                color: var(--msv-header-font-color);
                border: 1px solid transparent;
                margin-right: $header-nav-icon-margin-right-desktop;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                    color: var(--msv-accent-hover-focus-color);
                }

                &::before {
                    @include msv-icon();
                    content: $msv-GlobalNavButton;
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-hamburger-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                    color: inherit;
                }

                @media (max-width: $msv-breakpoint-m) {
                    height: 44px;
                    width: 44px;
                    margin-right: 0;
                }
            }

            .ms-header__desktop-logo {
                display: flex;

                .ms-header__phone-number {
                    display: flex;
                    align-items: center;
                    min-width: fit-content;
                    padding-left: 20px;
                    border-left: 1px solid var(--msv-divider-color);

                    .ms-header__phone-number-link {
                        color: var(--msv-phone-number-font-color);
                        font-weight: 700;

                        &:hover {
                            color: var(--msv-button-primary-hover-color); 
                            text-decoration: underline;
                        }
                    }
                }

                // Hide the phone on mobile
                @media (max-width: $msv-breakpoint-m) {
                    .ms-header__phone-number {
                        display: none;
                    }
                }
            }


            .ms-header__logo {
                display: block;
                margin-right: $header-logo-margin-right-desktop;
                flex-basis: 100%;

                @media screen and (max-width: $msv-breakpoint-m) {
                    margin-right: $header-logo-margin-right-mobile;
                }
                a {
                    @include vfi();
                    display: flex;
                }

                img {
                    width: auto;
                    height: auto;
                    min-width: $header-logo-min-width;
                }
            }

            .ms-header__mobile-logo {
                display: none;
            }

            .ms-header__preferred-store-container {
                .ms-header__preferred-store-btn {
                    border: 1px solid transparent;
                    display: flex;
                    height: $header-height;
                    line-height: $msv-line-height-m;
                    align-items: center;
                    padding: 0;
                    cursor: pointer;

                    @include vfi();
                    @include add-icon($msv-Shop-Address);
                    &:hover{
                        color: var(--msv-accent-hover-focus-color);
                    }

                    &::before {
                        font-size: var(--msv-header-icon-font-size);
                        display: inline-block;
                        width: auto;
                        text-align: center;
                        color: inherit;
                    }

                    .ms-header__preferred-store-text {
                        @include font-body-regular-m();
                        color: var(--msv-header-font-color);
                        white-space: nowrap;
                    }
                }
            }

            .ms-header__wishlist-desktop {
                display: none;
                width: $header-icon-width;
                height: $header-height;
                align-items: center;

                @include add-icon($msv-Heart, before, 400);

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                .msc-wishlist-icon__text {
                    display: none;
                }
            }

            .msc-cart-icon {
                display: flex;
                align-items: center;
                width: fit-content;

                &:hover {
                    text-decoration: none;
                    color: var(--msv-accent-hover-focus-color);
                }

                &::before {
                    @include msv-icon();
                    content: $msv-shopping-bag;
                    font-size: var(--msv-header-icon-font-size);
                    vertical-align: text-bottom;
                    text-align: center;
                    color: inherit;
                }

                &__count {
                    @include font-content-s();
                    color: var(--msv-header-font-color);
                    margin-bottom: $header-cart-count-margin-bottom;
                    padding: $header-cart-count-padding;
                    background-color: var(--msv-mini-cart-count-background-color);
                    border-radius: $header-cart-count-border-radius;
                    text-align: center;
                }
            }

            .ms-cart-icon-wrapper {
                border: 1px solid transparent;
                padding: 0;
                cursor: pointer;

                @include vfi();
            }

            .ms-header__account-info a:hover {
                text-decoration: none;
            }
        }

        .ms-header__collapsible-hamburger {
            position: absolute;
            width: 100%;
            left: 0;

            &.collapsing {
                display: none;
            }

            @media (min-width: $msv-breakpoint-l) {
                top: 104px;
            }

            @media (max-width: $msv-breakpoint-l) {
                top: 68px;
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }

    /* stylelint-disable no-descending-specificity -- Suppressed. */
    &.hide-header-options {
        .ms-header__nav-icon,
        .msc-cart-icon,
        .msc-wishlist-icon,
        .ms-header__account-info, 
        .mobile-navicon-container {
            display: none;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
                &:hover{
                    color: var(--msv-accent-hover-focus-color);
                }
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        .ms-search {
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            margin-top: 10px;
            background:white;
            border-radius: 0 !important;
            order: 2;
            align-items: baseline;
            padding-top: 0;
            margin-right: 20px;
            height: 42px;

            &::after {
                content: "";
                position: absolute;
                background: var(--msv-accent-brand-color);
                //width: 100vw;
                left: 0;
                bottom: 0;
                height: 57px;
                z-index: -1;
                font-size: 0;
            }
            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: none;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-header__preferred-store-container {
            .ms-header__preferred-store-btn {
                cursor: pointer;

                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .mobile-navicon-container {
            display: none;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        &__container {
            .ms-header__topbar {
                .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }

                .ms-header__preferred-store-container {
                    margin-right: 0;
                }

                .ms-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }

                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-top: $heading-padding-top;
        padding-bottom: $heading-padding-bottom;
        height: $header-height-l;
        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
                font-weight: normal;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        &__divider {
            display: none;
        }

        &__preferred-store-container {
            margin-right: $header-locator-margin-right-desktop;
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        .mobile-navicon-container {
            display: none;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-profile-button-text {
            display: none;
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);
            &:hover{
                color: var(--msv-accent-hover-focus-color);
            }

            &::before {
                @include font-body-regular-m();
                @include msv-icon();
                content: $msv-signin;
                color: inherit;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-top: $header-container-spacing-to-bottom-tablet;
        padding-bottom: $header-container-spacing-to-bottom-tablet;
        height: $header-container-height-tablet;
        &__container {
            .ms-header__topbar {
                .ms-header__mobile-logo {
                    //display: block;

                    a {
                        @include vfi();
                        padding: $header-logo-padding-mobile;
                    }
                }

                //.ms-header__logo {
                //    display: none;
                //}

                .ms-header__preferred-store-container {
                    .ms-header__preferred-store-btn {
                        cursor: pointer;

                        @include vfi();

                        &:hover{
                            color: var(--msv-accent-hover-focus-color);
                        }

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                            vertical-align: text-bottom;
                            text-align: center;
                            color: inherit;
                        }

                        .ms-header__preferred-store-text {
                            display: none;
                        }
                    }
                }

                .ms-header__account-info {
                    margin-left: $header-container-topbar-account-info-margin-left;

                    .ms-header__signin-button {
                        padding: $header-container-topbar-account-info-signin-button-padding;

                        .ms-header__signin-button-text {
                            display: none;
                        }
                    }
                }

                .ms-site-picker {
                    display: none;
                }
            }
        }
        .ms-profile-button-text {
            @include visually-hidden();
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);
            &:hover{
                color: var(--msv-accent-hover-focus-color);
            }
            &::before {
                @include font-body-regular-m();
                color: inherit;
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
            }
        }
    }

    @media (max-width: ($msv-breakpoint-m)) {
        &__container {
            margin-top: 18px;
            .ms-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin: 0;
                    flex-grow: 0;
                    padding-top: 7px;
                }

                .ms-header__account-info {
                    margin-right: 0;
                    //background-color: $msv-blue;
                }

                .ms-header__preferred-store-btn {
                    margin-right: 0;
                    cursor: pointer;

                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.ms-header__modal {
    @media (min-width: ($msv-breakpoint-m)) {
        display: none;
    }

    .msc-modal {
        .ms-header__mobile-hamburger {
            background: var(--msv-nav-bg);
            height: 100%;
            margin: 0;
            max-width: none;

            .msc-modal__content {
                height: 100%;
                padding: $header-modal-mobile-hamburger-content-padding;
                background: var(--msv-nav-bg);
                color: var(--msv-nav-font-color);

                .ms-header__mobile-hamburger-menu-body {
                    padding: $header-modal-mobile-hamburger-body-padding;

                    .msc-btn {
                        font-size: 16px;
                        height: unset;
                        line-height: 22px;
                        padding: $header-modal-mobile-hamburger-body-button-padding;
                    }
                }

                .msc-modal__header {
                    display: block;
                    height: 18px;

                    .msc-modal__close-button {
                        color: var(--msv-nav-font-color);
                        right: 0;
                        margin: 0;
                        opacity: 1;
                        padding-right: $header-modal-mobile-close-button-padding-right;
                        padding-top: $header-modal-mobile-close-button-padding-top;
                        z-index: 1000;

                        &:focus {
                            outline-offset: 0;
                        }

                        &:hover{
                            color: var(--msv-accent-hover-focus-color);
                        }

                        &::before {
                            @include msv-icon();
                            content: $msv-Cancel;
                            font-size: var(--msv-header-icon-font-size);
                            position: unset;
                            text-align: center;
                            vertical-align: text-bottom;
                            color: inherit;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
}

.skip-to-main {
    @include font-body-regular-s();
    color: $msv-black;
}

@media screen and (max-width: $msv-breakpoint-m) {
    .mobile-navicon-container {
        position: absolute;
        left: 10px;
    }
}

.ms-header__container .ms-header__topbar button.ms-header__nav-icon {
    margin-top: 10px;
    font-size: var(--msv-nav-title-font-size);
    align-items: center;
    background-color: var(--msv-header-desktop-nav-icon-background-color);
    color: var(--msv-header-font-color);
    display: inline-flex;
    justify-content: center;
    font-weight: 500;
    border-radius: 2px;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: $msv-breakpoint-m) {
        background-color: transparent;
        margin-top: 0;
        color: var(--msv-font-primary-color);
    }
    &:hover{
        color: var(--msv-accent-hover-focus-color);
        background-color: var(--msv-header-desktop-nav-icon-background-hover-color);
    }
    &::before {
        display: none;
        @media screen and (max-width: $msv-breakpoint-m) {
            display: block;
            color: inherit;
        }

    }
}

.gardner-site-links {
    min-height: unset;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $msv-breakpoint-m) {
        display: none;
    }
}
.gardner-site-links.gardner-site-links .ms-content-block__cta .msc-cta__primary, .gardner-site-links.gardner-site-links .ms-content-block__cta .msc-cta__secondary {
    position: relative;
    background-color: transparent;
    border: 0;
    color: var(--msv-font-primary-color);
    z-index: 1;
    padding: 4px 25px 0 25px;
    text-transform: uppercase;
    font-size: 14px;
}


.gardner-info-links {
    display: flex;
    justify-content: flex-end;
    min-height: 0px !important;

    &::before {
        background-color: var(--gardner-info-links-background-color);
        width: 100%;
        height: 100%;
        position: absolute;
        right: 50%;
        transform: translate(50%);
        content: "";
        visibility: visible;
        z-index: 0;
    }
}
.gardner-info-links.gardner-info-links .msc-cta__primary, .gardner-info-links.gardner-info-links .msc-cta__secondary {
    position: relative;
    background-color: transparent;
    border: 0;
    color: var(--msv-font-primary-color);
    z-index: 1;
    padding: 4px 10px 4px 4px;
    font-size: 12px;

    &::after {
        content: '|';
        padding: 0 10px;
    }
}
.gardner-site-links .ms-content-block__cta {
    transform: translateY(30px);
    @media (max-width: $msv-breakpoint-m) {
        transform: none;
    }
}
@media  (max-width: $msv-breakpoint-m) {
    .gardner-site-links, .gardner-info-links {
        display: none;
    }
}
