$footer-ms-footer-padding-xl: 30px 0px;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-min-width: fit-content;
$footer-category-padding-bottom-desktop: 40px;
$footer-category-padding-bottom-mobile: 20px;
$footer-heading-title-font-size: 18px;
$footer-heading-title-line-height: 40px;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-link-text-font-size: 18px;
$footer-link-text-line-height: 25px;
$footer-row-padding-top-mobile: 16px;
$footer-back-to-top-icon-font-size: 24px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;

//style presets
:root {
    //backgrounds
    --msv-footer-bg-color:  #{$gardner-gray-4};
    
    //fonts
    --copyright-text-color: #{$gardner-gray-5};
    --msv-footer-font-color: #FFFFFF;
}

footer {
    background-color: var(--msv-footer-bg-color);
    color: var(--msv-footer-font-color);
    padding: $footer-ms-footer-padding-xl;

    .copyright-container {
        display: flex;
        justify-content: flex-end;
        width: 100% !important;
        .copyright {
            font-size: 12px;
            text-align: right;
            color: var(--copyright-text-color);
        }
    }

    .ms-footer {
        padding-left: 0px;
        padding-right: 0px;
        &__list {
            flex: initial;
            width: fit-content;
            padding: 0 40px 0 20px;
            min-width: 200px;
        }

        .ms-back-to-top {
            @include vfi();
            border: 1px solid $msv-gray-60;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: $footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;

            .fa-arrow-up {
                color: $msv-gray-60;
                font-size: $footer-back-to-top-icon-font-size;
                left: 50%;
                line-height: $footer-back-to-top-line-height;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &::before {
                    @include msv-icon();
                    content: $msv-ChevronUp;
                }
            }
        }

        .ms-back-to-top[aria-hidden="false"] {
            display: block;
            opacity: 1;
            transform: translateX(0%);
        }

        &__heading__title {
            font-size: $footer-heading-title-font-size;
            font-weight: bold;
            line-height: $footer-heading-title-line-height;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1.1;
            font-size: 1rem;
        }

        &__link {
            @include vfi();
            display: inline-flex;
            color: var(--msv-footer-font-color);

            img {
                @include vfi();
            }

            div, &__text {
                font-size: $footer-link-text-font-size;
                font-weight: 100;
                line-height: $footer-link-text-line-height;
            }

            &:hover {
                text-decoration: none;
            }
        }

        & > * {
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding: $footer-ms-footer-padding-xl;

            > * {
                min-width: $footer-category-min-width;
                padding-bottom: $footer-category-padding-bottom-desktop;

                &:nth-child(4) {
                    .ms-footer__list {
                        display: flex;
                        width: auto;
                    }

                    .ms-footer__list-item:first-child {
                        position: absolute;
                        width: 100%;
                    }

                    .ms-footer__list-item:nth-child(1) {
                        position: absolute;
                        width: 100%;
                    }

                    .ms-footer__list-item:nth-child(2) {
                        position: relative;
                        top: $footer-list-item-icon-top;
                        width: 100%;
                    }

                    @for $i from 3 to ($footer-list-item-icon-number + 2) {
                        .ms-footer__list-item:nth-child(#{$i}) {
                            left: $footer-list-item-icon-left * ($i - 2);
                            position: relative;
                            top: $footer-list-item-icon-top;
                            width: 100%;
                        }
                    }
                }
            }
        }

    }


    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;
            padding: 30px 0 30px 0;
            > * {
                padding-top: $footer-row-padding-top-mobile;

                > * {
                    padding-bottom: $footer-category-padding-bottom-mobile;
                }
            }

            &__list {
                padding-top: $footer-row-padding-top-mobile;
            }
        }
    }


}
