$footer-ms-footer-padding-xl: 30px 0px;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-min-width: fit-content;
$footer-category-padding-bottom-desktop: 40px;
$footer-category-padding-bottom-mobile: 20px;
$footer-heading-title-font-size: 18px;
$footer-heading-title-line-height: 40px;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-link-text-font-size: 18px;
$footer-link-text-line-height: 25px;
$footer-row-padding-top-mobile: 16px;
$footer-back-to-top-icon-font-size: 24px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;

//style presets
:root {
    //footer
    --msv-footer-bg:  #{$gardner-gray-4};
    --copyright-text-color: #{$gardner-gray-5};
}

footer {
    .b2c-footer-top-container {
        & > div {
            justify-content: center;
        }

        .order-details-container {
            .ms-footer__list {
                margin: 0 auto;
                text-align: center;
                padding: 0 20px;
                margin-bottom: 10px;
            }

            .credit-card-container {
                display: flex;
                justify-content: center;

                .credit-card-logos {
                    height: 32px;
                    min-height: auto;
                    margin-bottom: 20px;
                    
                    img {
                        height: 32px;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .b2c-footer-bottom-container {
        & > div {
            justify-content: space-between;
        
            & > div {
                align-self: center;
            }
        }

        .terms-container {
            padding: 0;
            min-width: fit-content;

            .ms-footer__link div {
                font-size: .75rem;
                font-weight: 400;
                letter-spacing: .5px;
                line-height: 0;
            }
        }

        .copyright-container {
            .copyright {
                margin-top: 0;
            }
        }

        .norton-container {
            display: flex;
            align-items: center;
            padding-right: 0;

            .ms-content-block {
                min-height: auto;

                img {
                    height: 50px;
                    object-fit: contain;
                    object-position: right;
                }

                .ms-content-block__image {
                    margin-bottom: 0;
                }

                @media (max-width: $msv-breakpoint-l) {
                    .ms-content-block__details {
                        padding: 0;
                    }
                }
            }

            @media (max-width: $msv-breakpoint-m) {
                align-items: normal;
                padding-left: 0;

                & > .row {
                    flex-direction: row;
                    & > div {
                        max-width: 50%;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            .row {
                flex-direction: column;
                align-items: center;
            }

            .copyright-container {
                padding: 10px 0;
            }

            .norton-container {

            }
        }
    }
}