
@media print{
    header,
    footer{
        display: none;
    }

    .msc-invoice-details__products picture,
    .msc-cart-line .msc-cart-line__product-image{
        display: none;
    }

    #main .default-container{
        max-width: unset;
    }
}
