.ms-tab {
  max-width: fit-content;
  margin: 0 auto;
  &-header-section {
    margin: 5px 5px 20px 5px;

    &__heading {
      @include font-content-xl();
    }
  }

  @media (min-width: $msv-breakpoint-m) {
    &-header-section {
      display: flex;
      margin: 5px 5px 20px 5px;
    }
  }

  &-tabs-section {
    display: flex;
    flex-wrap: wrap;
    &-li {
      cursor: pointer;
      padding: 16px;
      margin: 10px 5px 0 5px;
      position: relative;
      background-color: #{$gardner-gray-4};
      color: var(--msv-font-secondary-color);
      text-transform: uppercase;
      min-width: 198px;
      text-align: center;
      :hover {
        text-decoration: none;
      }

      &[aria-selected="true"] {
        background-color: transparent;
        color: var(--msv-font-primary-color);
        text-decoration: underline;
        border: 1px solid var(--msv-font-primary-color);
      }

      @media screen and (max-width: $msv-breakpoint-m) {
        width: 100%;
      }

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      ::before {
        background-color: transparent;
        bottom: 0;
        content: "";
        height: 2px;
        left: 8px;
        position: absolute;
        right: 8px;
        transition: background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9);
      }
    }
  }

  &-content-section {
    margin-top: 20px;
  }

  &-item-content__content {
    &[aria-hidden="true"] {
      display: none;
    }
  }
}
