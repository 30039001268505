.gardner-map {
  max-width: 562px;
  margin-left: auto;
  margin-right: auto;
}

.gardner-commitments-image-list,
.gardner-image-list-text-only {
  margin: 0 auto !important;
  width: fit-content;
}

.gardner-image-list-text-only .ms-image-list__text {
  * {
    background-color: transparent !important;
    max-width: 800px;
    width: 100%;
    text-align: center;
    color: $msv-font-primary-color !important;
    margin: 0 auto;
    font-weight: 100;
    font-family: $msv-primary-font-family;
  }

  ul.numbered-list {
    margin: 0 0 20px 0 !important;
    @media screen and (max-width: $msv-breakpoint-m) {
      li {
        max-width: max-content !important;
        padding: 0 50px;
      }
    }
  }
}

.gardner-image-list-text-only {
  .ms-image-list__list {
    flex-flow: row;

    @media screen and (max-width: $msv-breakpoint-m) {
      flex-flow: column;
    }
  }
}

@media screen and (max-width: $msv-breakpoint-m) {

  .gardner-welcome-banner.ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__title,
  .ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__text,
  .gardner-quality-brands.ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__title,
  .ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__text,
  .gardner-quality-brands.ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__title,
  .ms-content-block[data-m-layout="full-width"].texttheme__light .ms-content-block__text {
    color: black !important;
  }
}

.gardner-commitments-image-list .ms-image-list__list,
.gardner-contact-tab .ms-tab-tabs-section,
.gardner-brands-tab .ms-tab-tabs-section,
.gardner-brands-tab .ms-image-list__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100vw;
}

//.gardner-contact-tab .ms-tab-content-section {
//  overflow: hidden;
//  height: 993px;
//}
//
//@media screen and (min-width: 768px) {
//  .gardner-contact-tab .ms-tab-content-section {
//    overflow: hidden;
//    height: 796px;
//  }
//}

.gardner-brand-tab .ms-tab-content-section {
  overflow: hidden;
  height: 435px;
}

@media screen and (min-width: 440px) {
  .gardner-brand-tab .ms-tab-content-section {
    height: 235px;
  }
}

@media screen and (min-width: 840px) {
  .gardner-brand-tab .ms-tab-content-section {
    height: 135px;
  }
}


.gardner-commitments-image-list .msc-main_image {
  width: 72px;
  height: 72px;
}

.gardner-commitments-image-list .ms-image-list__list-item {
  padding: 0;
  width: 255px;
  justify-content: start;
  margin-bottom: 18px;
}

.gardner-commitments-image-list .ms-image-list__link {
  padding: 0;
}

.gardner-commitments-image-list .ms-image-list__text p:first-child {
  font-size: 60px;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 1rem;
}

.gardner-commitments-image-list .ms-image-list__text p:last-child {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
}

.gardner-reach-image {
  max-width: 900px;
  margin: 0 auto;
}

.gardner-reach-image.ms-content-block[data-m-layout="full-width"] .ms-content-block__details {
  background: none;
}

.gardner-map.ms-content-block[data-m-layout="full-width"] .ms-content-block__details {
  background: none;
}

.gardner-tab .ms-image-list__list-item {
  border: 1px solid #f3f3f3;
  max-width: 200px;
}

.gardner-welcome-banner .msc-cta__primary:active {
  text-decoration: none;
}
@media screen and (min-width: $msv-breakpoint-l) {
  .gardner-welcome-banner .ms-content-block__title {
    color: var(--msv-content-block-textheme-light) !important;
  }
  .gardner-welcome-banner .ms-content-block__text {
    color: var(--msv-content-block-textheme-light) !important;
  }
}

.gardner-contact-block-without-button-styles a {
  border: 0 !important;
  background-color: transparent !important;
}

.gardner-content-block-text-above {
  display: flex;
  flex-flow: column;
}

.gardner-content-block-text-above .ms-content-block__details {
  order: 1;
  position: unset !important;
  margin: 0 auto 20px !important;
  padding: 0 !important;
  max-width: 1047px;
}

.gardner-content-block-text-above .ms-content-block__image {
  order: 2;
  max-width: 1047px;
  margin: 0 auto;
}

.gardner-content-block-text-above .ms-content-block__text {
  max-width: 1047px !important;
}

.gardner-content-block-no-image>.ms-content-block__details {
  position: relative !important;
}
/* 
.gardner-image-list-orange-icons .ms-image-list__link, .gardner-image-list-orange-icons-vertical .ms-image-list__link {
  background-color: #D00B14;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  padding: 1em;
}

.gardner-image-list-orange-icons .ms-image-list__list,
.gardner-image-list-blue-icons .ms-image-list__list,
.gardner-image-list-blue-icons-vertical .ms-image-list__list,
.gardner-image-list-orange-icons-vertical .ms-image-list__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1047px;
  margin: 0 auto;
}

.gardner-image-list-orange-icons-vertical .ms-image-list__list,
.gardner-image-list-blue-icons-vertical .ms-image-list__list {
  flex-flow: column;
  width: fit-content;
  margin: 0 auto;
}

.gardner-image-list-orange-icons .ms-image-list__list-item,
.gardner-image-list-blue-icons .ms-image-list__list-item,
.gardner-image-list-blue-icons-vertical .ms-image-list__list-item,
.gardner-image-list-orange-icons-vertical .ms-image-list__list-item {
  flex-flow: column;
  min-width: 200px;
  width: 33%;
  margin: 0 auto 28px;
}

.gardner-image-list-orange-icons .ms-image-list__text *,
.gardner-image-list-blue-icons .ms-image-list__text *,
.gardner-image-list-blue-icons-vertical .ms-image-list__text *,
.gardner-image-list-orange-icons-vertical .ms-image-list__text *,
.gardner-image-list-employment .ms-image-list__text * {
  background-color: transparent !important;
}

.gardner-image-list-blue-icons .ms-image-list__link,
.gardner-image-list-blue-icons-vertical .ms-image-list__link {
  background-color: #003B64;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
} */

.gardner-image-list-employment .ms-image-list__link {
  width: 70px;
  height: 70px;
}

.gardner-image-list-employment {
  max-width: 1047px;
  margin: 0 auto;
}

.gardner-quality-brands {
  max-height: 500px;
  overflow: hidden;
  background-position: center;
}

.gardner-carousel,
.ms-image-list {
  max-width: 1047px;
  margin: 0 auto;
}

.gardner-featured-brands h2 {
  margin: 0 auto;
}

.gardner-carousel img {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}

.gardner-carousel {
  .msc-carousel__indicators {
    bottom: -4rem;
  }
}

.gardner-contact-block .ms-content-block__image {
  margin: 0 !important;
}

.gardner-contact-block .ms-content-block__details {
  margin: 0 !important;
}

.gardner-contact-block.gardner-contact-block .ms-content-block__details p {
  text-align: left !important;
}

.gardner-contact-block.gardner-contact-block .ms-content-block__details a {
  text-align: inherit !important;
}

.ms-tab.gardner-contact-tab p {
  margin-left: 12px;
}