$gar-text-area-border-color: #cccccc;
$gar-text-area-height: 120px;
$gar-text-area-width: 100%;
$gar-text-area-padding: 5px;
$gar-text-area-border-size: 3px;
$gar-cancel-button-margin-left: 10px;
$gar-modal-header-font-size: 25px;
.gar-order-comment {

  @media screen and (max-width: $msv-breakpoint-m) {
    margin-top: 3rem;
  }
  &__link {
    @include link-regular-s();
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    padding-top: 15px;
    text-decoration: underline;
    cursor: pointer;

    @media screen and (max-width: $msv-breakpoint-m) {
      margin-left: 0;
      display: block;
    }
  }
  &__textarea {
    width: $gar-text-area-width;
    height: $gar-text-area-height;
    border: $gar-text-area-border-size solid $gar-text-area-border-color;
    padding: $gar-text-area-padding;
  }
  &__button {
    &--save {
      @include primary-button();
    }
    &--cancel {
      @include secondary-button();
      margin-left: $gar-cancel-button-margin-left;
    }
  }
  &__modal-header.msc-modal__header .msc-modal__title {
    font-size: $gar-modal-header-font-size;
  }
  &__static-display {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $msv-breakpoint-m) {
      align-items: flex-start;
    }
  }
}
.order-comment-container.order-comment-container__print-view {
    a.gar-order-comment__link {
        // Hide the Edit Comment link while in Print View
        display: none;
    }
}